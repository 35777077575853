import * as _ from 'lodash';

export default {
  name: "ToolsMixin",
  mixins: [],
  components: {},
  props: {},
  data() {
    return {
      
    };
  },
  computed: {},
  created: function() {},
  methods: {
    sortSendTimeDesc(items) {
      items.sort((a, b) => {
        return b.sendTime - a.sendTime;
      });
      // return _.sortBy(items, [(o) => {
      //   return o.sendTime;
      // }]);

      return items.reverse();
    },
    formattedToSecondsSendTime(input) {
      let sendTime =  this.$store.state.sim.exercise.currentTime;

      if(!input) {
        return sendTime;
      }
      
      let pieces = input.split(':');
      if(pieces.length == 3) {
        sendTime = parseInt(pieces[0]) * 3600 + parseInt(pieces[1]) * 60 + parseInt(pieces[2]);
      } else if(pieces.length == 2) {
        sendTime = parseInt(pieces[0]) * 60 + parseInt(pieces[1]);
      } else if(pieces.length == 1) {
        sendTime = parseInt(pieces[0]);
      }

      return sendTime;
    },
    secondsToFormattedSendTime(inputSeconds) {
        if(inputSeconds === "" || inputSeconds === null) {
            return "";
        }

        let hours = Math.floor(inputSeconds / 3600);
        let minutes = Math.floor((inputSeconds % 3600) / 60);
        let seconds = Math.floor((inputSeconds % 3600) % 60);

        let paddedHours = _.padStart(String(hours), 2, '0');
        let paddedMinutes = _.padStart(String(minutes), 2, '0');
        let paddedSeconds = _.padStart(String(seconds), 2, '0');

        if(hours) {
            return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
        } else if(minutes) {
            return `${paddedMinutes}:${paddedSeconds}`;
        } else {
            return `${paddedSeconds}`;
        }
    },
    idsToObject(ids, candidates) {
        let objects = [];

        _.each(ids, (id) => {
            const item = _.find(candidates, (c) => {
                return c._id === id;
            });

            if(item) {
              objects.push(item);
            }
        });

        return objects;
    },
    objectToIds(objects) {
        let ids = [];

        _.each(objects, (ob) => {
            ids.push(ob._id);
        });

        return ids;
    },
    globalCanShow(exercise, item) {
        // https://docs.google.com/spreadsheets/d/1MhDSp4QrQCx2MnoQ94N2ta4fQvspVv3ZtU9X8VFtqTY/edit#gid=0
        if(!item.actionTime) {
            if(exercise.currentTime < item.sendTime) {
                return false;
            } else {
                return true;
            }
        } else {
            if(exercise.currentTime < item.sendTime) {
                return false;
            } else if(item.sendTime <= exercise.currentTime && exercise.currentTime <= item.actionTime) {
                return true;
            } else {
                return false;
            }
        }
    },
    canShowAtCurrentTime(exercise, item) {
        // https://docs.google.com/spreadsheets/d/1MhDSp4QrQCx2MnoQ94N2ta4fQvspVv3ZtU9X8VFtqTY/edit#gid=0

        // Exit if exercise is a shell (i.e. not set).
        if(!exercise._id) {
          return true;
        }

        if(!_.isNumber(item.actionTime)) {
            if(exercise.currentTime < item.sendTime) {
                return false;
            } else {
                return true;
            }
        } else {
          if(exercise.currentTime > item.actionTime) {
            return false;
          }

          // Special case for when time is 0.
          if(exercise.currentTime === 0 && item.actionTime === 0) {
            return false;
          }

          // Special case for when exercise is paused.
          if(exercise.status == 'paused' && exercise.currentTime == item.actionTime) {
            return false;
          }

          if(exercise.currentTime < item.sendTime) {
              return false;
          } else if(item.sendTime <= exercise.currentTime && exercise.currentTime <= item.actionTime) {
              return true;
          } else {
              return false;
          }
        }
    },
    isMine(item, role) {
      return item.role == role._id;
    },
    isFromMeOrToMe(item, role) {
      return item.toRole == role._id || item.fromRole == role._id;
    },
    // hasPermission(permission) {
    //   if(!this.$store.state.sim.role._id) {
    //     return false;
    //   }

    //   return this.$store.state.sim.role.permissionList.indexOf(permission) >= 0;
    //   // return false;
    // },
    isExerciseController() {
      return this.$store.state.sim.role.controller;
    },

  },
};
