<template>
    <div class="content tw-bg-gray-200">

        <b-modal
            id="modal-sim-fullscreen"
            v-model="fullScreenModal"
            centered
            scrollable
            size="xl"
        >
            <component :is="fullScreenWidget" :full-screen="true" :exercise="exercise"></component>
        </b-modal>

        <div class="floating-clock">
            <b-badge class="py-2 px-4" variant="light">
                <i class="fas fa-user-clock"></i>
                {{ secondsToFormattedSendTime(exercise.currentTime) }}
            </b-badge>
        </div>

        <div class="floating-role" v-if="$store.state.sim.role">
            {{ $store.state.sim.role.name }}
        </div>

        <div class="icon-drawer icon-drawer-left">
            <b-button
                variant="secondary"
                outline
                @click="scrollToWidget('#controlWidget')"
                class="tw-my-1"
                title="Exercise Control/Info"
            >
                <i class="fas fa-play"></i>
            </b-button>

            <b-button
                v-if="$store.state.sim.role.controller"
                variant="secondary"
                @click="scrollToWidget('#simcellWidget')"
                class="tw-my-1"
                title="Simcell"
            >
                <i class="fas fa-city"></i>
            </b-button>

            <b-button
                v-if="$store.state.sim.role.controller"
                variant="secondary"
                @click="setLeftDrawerWidget('ExerciseInjectAllInjectList')"
                class="tw-my-1"
                title="All Injects"
            >
                <i class="fas fa-layer-group"></i>
            </b-button>

            <b-button
                variant="secondary"
                outline
                @click="scrollToWidget('#mapWidget')"
                class="tw-my-1"
                title="Disaster Map"
            >
                <i class="fas fa-map-marked"></i>
            </b-button>

            <!-- <b-button
                variant="primary"
                outline
                @click="setLeftDrawerWidget('MySimInjectList')"
                class="tw-my-1"
            >
                <i class="fas fa-globe"></i>
            </b-button> -->

            <!-- <b-button
                variant="primary"
                outline
                @click="setLeftDrawerWidget('MySimInjectList')"
                class="tw-my-1"
                title="Injects"
            >
                <i class="fas fa-bullhorn"></i>
            </b-button> -->

            <b-button
                v-if="$store.state.sim.role._id"
                variant="secondary"
                outline
                @click="scrollToWidget('#injectWidget')"
                class="tw-my-1"
                title="Injects"
            >
                <i class="fas fa-bullhorn"></i>
            </b-button>

            <b-button
                v-if="$store.state.sim.role._id"
                variant="secondary"
                outline
                @click="scrollToWidget('#broadcastWidget')"
                class="tw-my-1"
                title="Broadcast"
            >
                <i class="fas fa-tv"></i>
            </b-button>

            <b-button
                v-if="$store.state.sim.role._id"
                variant="secondary"
                outline
                @click="scrollToWidget('#social-mediaWidget')"
                class="tw-my-1"
                title="Socail Media"
            >
                <i class="fas fa-hashtag"></i>
            </b-button>

            <b-button
                v-if="$store.state.sim.role._id"
                variant="secondary"
                outline
                @click="scrollToWidget('#weatherWidget')"
                class="tw-my-1"
                title="Weather"
            >
                <i class="fas fa-cloud"></i>
                <b-badge variant="warning" style="position: absolute; left: 0px; top: -5px;">2</b-badge>
            </b-button>

            <b-button
                variant="dark"
                outline
                :to="{ name: 'Dashboard' }"
                class="tw-my-5"
                title="Exit Exercise"
            >
                <i class="far fa-window-close"></i>
            </b-button>

        </div>

        <div class="icon-drawer icon-drawer-right">
            <!-- <b-button
                variant="secondary"
                outline
                @click="scrollToWidget('#weatherWidget')"
                class="tw-my-1"
                title="Weather"
            >
                <b-badge variant="warning" class="mr-1">2</b-badge>
                <i class="fas fa-cloud"></i>
                
            </b-button>

            <b-button
                variant="secondary"
                outline
                @click="scrollToWidget('#weatherWidget')"
                class="tw-my-1"
                title="Weather"
            >
                <i class="fas fa-cloud"></i>
                <b-badge variant="warning" class="ml-1">2</b-badge>
            </b-button> -->

            <b-button
                v-if="$store.state.sim.role.controller"
                variant="primary"
                outline
                @click="setRightDrawerWidget('ScribeSimWidget')"
                class="tw-my-1"
                title="Scribe"
            >
                <i class="fas fa-keyboard"></i>
                <!-- <b-badge variant="danger" style="position: absolute; left: 0px; top: 43px;">2</b-badge> -->
            </b-button>

            <b-button
                v-if="$store.state.sim.role._id && !$store.state.sim.role.controller"
                variant="primary"
                outline
                @click="setRightDrawerWidget('PositionLogSimWidget')"
                class="tw-my-1"
                title="Position Log"
            >
                <i class="fas fa-keyboard"></i>
            </b-button>

            <b-button
                v-if="$store.state.sim.role._id && !$store.state.sim.role.controller"
                variant="primary"
                @click="setRightChatDrawerWidget('ChatSimWidget')"
                class="tw-my-1"
                title="Chat"
            >
                <i class="fas fa-comments"></i>
                <b-badge variant="danger" style="position: absolute; left: 0px; top: 90px;">3</b-badge>
            </b-button>

            <b-button
                variant="secondary"
                outline
                @click="scrollToWidget('#roleWidget')"
                class="tw-my-1"
                title="Positions"
            >
                <i class="fas fa-users"></i>
            </b-button>

            <b-button
                v-if="$store.state.sim.role._id"
                variant="secondary"
                @click="scrollToWidget('#statusBoardWidget')"
                class="tw-my-1"
                title="Status Board"
            >
                <i class="fas fa-chart-bar"></i>
            </b-button>

            <b-button
                v-if="$store.state.sim.role._id && !$store.state.sim.role.controller"
                variant="secondary"
                outline
                @click="scrollToWidget('#taskWidget')"
                class="tw-my-1"
                title="Tasks"
            >
                <i class="fas fa-tasks"></i>
            </b-button>

            <b-button
                v-if="$store.state.sim.role.controller"
                variant="secondary"
                @click="scrollToWidget('#inventoryWidget')"
                class="tw-my-1"
                title="Inventory"
            >
                <i class="fas fa-boxes"></i>
            </b-button>

            <b-button
                v-if="$store.state.sim.role._id"
                variant="secondary"
                @click="scrollToWidget('#resourceRequestWidget')"
                class="tw-my-1"
                title="Resource Requests"
            >
                <i class="fas fa-truck"></i>
            </b-button>

            <b-button
                v-if="$store.state.sim.role._id"
                variant="secondary"
                @click="scrollToWidget('#spreadsheetWidget')"
                class="tw-my-1"
                title="Spreadsheet"
            >
                <i class="fas fa-table"></i>
            </b-button>

            <b-button
                v-if="isDebugVisible()"
                variant="danger"
                outline
                @click="words()"
                class="tw-my-1"
                title="Words"
            >
                <i class="fas fa-file-word"></i>
            </b-button>

            <b-button
                v-if="isDebugVisible()"
                variant="danger"
                outline
                @click="paragraph()"
                class="tw-my-1"
                title="Paragraph"
            >
                <i class="fas fa-paragraph"></i>
            </b-button>

            <b-button
                v-if="isDebugVisible()"
                variant="danger"
                outline
                @click="paragraphs()"
                class="tw-my-1"
                title="Paragraphs"
            >
                <i class="fas fa-align-left"></i>
            </b-button>
        </div>

        <!-- <div
            class="drawer drawer-left"
            v-if="leftDrawer"
            :class="{ active: leftDrawer }"
        >
            <div>
                <div class="row">
                    <div class="text-right col">
                        <b-button link @click="closeLeftDrawer">
                            <i class="fas fa-times"></i>
                        </b-button>
                    </div>
                </div>
            </div>
            <hr />
            <component :is="leftWidget" :exercise="exercise"></component>
        </div> -->

        <b-sidebar width="70em" v-model="leftDrawer" id="sidebar-drawer-left" title="" shadow bg-variant="light">
            <div class="px-3 py-2">
                <component :is="leftWidget" :exercise="exercise"></component>
            </div>
        </b-sidebar>

        <b-sidebar width="50em" v-model="rightDrawer" id="sidebar-drawer-right" title="" right shadow>
            <div class="px-3 py-2">
                <component :is="rightWidget" :exercise="exercise"></component>
            </div>
        </b-sidebar>

        <b-sidebar width="100em" v-model="rightChatDrawer" id="sidebar-drawer-right-chat" title="" right shadow>
            <div class="px-3 py-2">
                <component :is="rightChatWidget" :exercise="exercise"></component>
            </div>
        </b-sidebar>

        <!-- <div class="drawer drawer-right" v-if="rightDrawer">
            <div>
                <div class="row">
                    <div class="text-left col">
                        <b-button link>
                            <i class="fas fa-times"></i>
                        </b-button>
                    </div>
                </div>
            </div>
        </div> -->

        <!-- <b-container fluid>
            <b-row>
                <b-col class="text-center">
                    <b-button @click="words()" variant="secondary" class="mr-2">
                        Copy Words to Clipboard
                    </b-button>
                    <b-button @click="paragraphs()" class="mr-2">
                        Copy Paragraphs to Clipboard
                    </b-button>
                    <b-button @click="paragraph()" variant="warning">
                        Copy Paragraph to Clipboard
                    </b-button>
                </b-col>
            </b-row>
        </b-container> -->

        <b-container fluid v-if="isDebugVisible()">
            <b-row v-if="isExerciseController()">
                    <b-col class="tw-m-16">
                        <b-form-group
                        id="currentTime-group"
                        label="Current Time:"
                        label-for="currentTime"
                    >
                        <b-form-input
                            id="currentTime"
                            v-model="currentTime"
                            required
                            placeholder="Current Time"
                            @keydown.enter="setCurrentExerciseTime"
                        ></b-form-input>
                    </b-form-group>
                    <b-button @click="setCurrentExerciseTime">Set Current Exercise Time</b-button>
                    <b-button @click="setCurrentExerciseTimeIncrement(1)" variant="warning" class="tw-ml-2">Increment</b-button>
                    <b-button @click="setCurrentExerciseTimeIncrement(-1)" variant="warning" class="tw-ml-2">Decrement</b-button>
                    <b-button @click="resetCurrentExerciseTime" variant="danger" class="tw-ml-2">Reset</b-button>
                    </b-col>
                </b-row>
            <b-row>
                <b-col class="text-left tw-mx-16 tw-bg-red-200">
                    <h2>Exercise</h2>
                    <ShowObject
                        :input="$store.state.sim.exercise"
                        :cols="['_id', 'name', 'status', 'currentTime', 'speed']"
                    />

                    <hr>

                    <div class="px-3 py-2">
                        <!-- <div v-for="role in $store.state.sim.roles">
                            {{ role.name }} ({{ role._id }}) - {{  role.sendTime }} - {{ role.actionTime }}
                            {{ role.name }} ({{ role._id }})
                        </div> -->
                    </div>
                </b-col>
                <b-col class="text-left tw-mx-16 tw-bg-red-200">
                    <h2>Role ({{$store.getters['sim/getRoleID']}})</h2>
                    <ShowObject
                        :input="$store.state.sim.role"
                        :cols="['_id', 'role', 'name','exercise', 'simcell', 'primaryUser', 'controller', 'permissions', 'permissionList', 'sendTime', 'actionTime']"
                    />
                    <hr>

                    <div class="px-3 py-2">
                        <div v-for="simcell in $store.state.sim.simcells">
                            {{ simcell.name }} ({{ simcell._id }}) - {{ simcell.sendTime }} - {{ simcell.actionTime }}
                        </div>
                    </div>
                </b-col>
                <b-col class="text-left tw-mx-16 tw-bg-red-200">
                    <h2>User</h2>
                    <ShowObject
                        :input="$store.state.user.user"
                        :cols="['_id', 'name', 'email', 'isSuperUser', 'systemRoles']"
                    />
                </b-col>
            </b-row>
        </b-container>

        <div class="inline-widget-content-padding tw-px-10" v-if="exercise._id">
            <div class="row">
                <div class="col-xl">
                    <ControlWidget
                        v-if="exercise"
                        :exercise="exercise"
                        v-on:replayupdateexerciseinfo="replayUpdateExerciseInfo($event)"
                    />

                    <WeatherSimWidget
                        v-if="$store.state.sim.role._id"
                        v-on:setfullscreenmodalwidget="setFullscreenModalWidget($event)"
                        :exercise="exercise"
                    />
                </div>

                <div class="col-xl">
                    <RoleSimWidget
                        v-on:setfullscreenmodalwidget="setFullscreenModalWidget($event)"
                        :exercise="exercise"
                    />

                    <SimcellSimWidget
                        v-if="$store.state.sim.role.controller"
                        v-on:setfullscreenmodalwidget="setFullscreenModalWidget($event)"
                        :exercise="exercise"
                    />
                </div>
            </div>

            <div class="row">
                <div class="col-xl">
                    <MapSimWidget
                        v-if="exercise"
                        :exercise="exercise"
                        v-on:setfullscreenmodalwidget="setFullscreenModalWidget($event)"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col-xl">

                    <InjectSimWidget
                        v-if="$store.state.sim.role._id"
                        v-on:setfullscreenmodalwidget="setFullscreenModalWidget($event)"
                        :exercise="exercise"
                    />

                    <BroadcastSimWidget
                        v-if="$store.state.sim.role._id"
                        v-on:setfullscreenmodalwidget="setFullscreenModalWidget($event)"
                        :exercise="exercise"
                    />

                    <BroadcastSimWidget
                        v-if="$store.state.sim.role._id"
                        type="social-media" v-on:setfullscreenmodalwidget="setFullscreenModalWidget($event)"
                        :exercise="exercise"
                    />

                </div>
                <div class="col-xl">

                    <StatusBoardSimWidget
                        v-if="$store.state.sim.role._id"
                        v-on:setfullscreenmodalwidget="setFullscreenModalWidget($event)"
                        :exercise="exercise"
                    />  

                    <TaskSimWidget
                        v-if="$store.state.sim.role._id"
                        v-on:setfullscreenmodalwidget="setFullscreenModalWidget($event)"
                        :exercise="exercise"
                    />

                    <InventorySimWidget
                        v-if="$store.state.sim.role._id"
                        v-on:setfullscreenmodalwidget="setFullscreenModalWidget($event)"
                        :exercise="exercise"
                    />

                    <ResourceRequestSimWidget
                        v-if="$store.state.sim.role._id"
                        v-on:setfullscreenmodalwidget="setFullscreenModalWidget($event)"
                        :exercise="exercise"
                    />

                    <SpreadsheetSimWidget
                        v-if="$store.state.sim.role._id"
                        v-on:setfullscreenmodalwidget="setFullscreenModalWidget($event)"
                        :exercise="exercise"
                    />                  

                </div>
            </div>
        </div>
    </div>
</template>

<script>

import SocketIOMixin from '@/util/socketio';

import ControlWidget from "@/components/SimEOC/control/ControlWidget.vue";
import RoleSimWidget from "@/components/SimEOC/role/RoleSimWidget.vue";
import ScribeWidget from "@/components/SimEOC/scribe/ScribeWidget.vue";

import InjectWidget from "@/components/SimEOC/inject/InjectWidget.vue";
import GlobalInjectSimList from "@/components/SimEOC/global-inject/GlobalInjectSimList.vue";
import GlobalInjectSim from "@/components/SimEOC/global-inject/GlobalInjectSim.vue";

import PersonalInjectSim from "@/components/SimEOC/personal-inject/PersonalInjectSim.vue";

import PositionLogWidget from "@/components/SimEOC/position-log/PositionLogWidget.vue";
import SignificantEventWidget from "@/components/SimEOC/significant-event/SignificantEventWidget.vue";

import WeatherWidget from "@/components/SimEOC/weather/WeatherWidget.vue";
// import TaskWidget from "@/components/SimEOC/task/TaskWidget.vue";

import StatusBoardWidget from "@/components/SimEOC/status-board/StatusBoardWidget.vue";

import InventoryWidget from "@/components/SimEOC/inventory/InventoryWidget.vue";

import BroadcastWidget from "@/components/SimEOC/broadcast/BroadcastWidget.vue";

import * as faker from 'faker';
import * as _ from 'lodash';
import { mapState } from "vuex";



export default {
    mixins: [SocketIOMixin],
    components: {
        ControlWidget,
        RoleSimWidget,
        ScribeWidget,
        GlobalInjectSimList,
        GlobalInjectSim,
        PersonalInjectSim,
        InjectWidget,
        PositionLogWidget,
        SignificantEventWidget,
        WeatherWidget,
        // TaskWidget,
        StatusBoardWidget,
        InventoryWidget,
        BroadcastWidget,
    },
    data() {
        return {
            visibleRight: true,
            leftDrawer: false,
            rightDrawer: false,
            rightChatDrawer: false,
            leftWidget: null,
            rightWidget: null,
            rightChatWidget: null,
            fullScreenModal: false,
            fullScreenWidget: '',

            exercise: {},
            socketEventName: "",
            currentTime: 0,
            originalCurrentTime: 0
        };
    },
    computed: {
        ...mapState("user", ["user"]),
    },
    async created() {
        await this.get();
        this.currentTime = this.exercise.currentTime;
        this.originalCurrentTime = this.currentTime;
    },
    sockets: {
        connect: function () {
            console.log('connected');
        },
        disconnect: function () {
            console.log('disconnected');
        },
        
    },
    mounted() {},
    methods: {
        async get() {
            const that = this;

            const id = that.$route.params.id;

            // Check localStorage for role for this exericse.
            if(localStorage.getItem(`${id}-role`)) {
                that.$store.commit('sim/setRole', JSON.parse(localStorage.getItem(`${id}-role`)));
            }

            // Initialize exercise and set it in store.
            that.exercise = await that.$http.get(
                `/exercise/${that.$route.params.id}`
            ).then((response) => response.data);

            that.$store.commit('sim/setExercise', _.cloneDeep(that.exercise));

            // that.subscribe(`${that.exercise._id}-currentTime`, (data) => {
            //     that.exercise.currentTime = data;
            //     that.$store.commit('sim/setExerciseCurrentTime', data);
            // });

            that.socketIOSubscribe({
                name: `${that.exercise._id}-currentTime`,
                debug: true,
                callback: (data) => {
                    that.exercise.currentTime = data;
                    that.$store.commit('sim/setExerciseCurrentTime', data);
                }
            });

            that.subscribe(`${that.exercise._id}`, (data) => {
                that.exercise = data;
            });
        },
        setCurrentExerciseTime() {
            this.exercise.currentTime = parseInt(this.currentTime);
            this.$store.commit('sim/setExerciseCurrentTime', this.exercise.currentTime);
        },
        setCurrentExerciseTimeIncrement(x) {
            this.exercise.currentTime += x;
            this.$store.commit('sim/setExerciseCurrentTime', this.exercise.currentTime);
            this.currentTime = this.exercise.currentTime;
        },
        resetCurrentExerciseTime() {
            this.exercise.currentTime = this.originalCurrentTime;
            this.$store.commit('sim/setExerciseCurrentTime', this.exercise.currentTime);
            this.currentTime = this.exercise.currentTime;
        },
        setLeftDrawerWidget: function (widget) {
            this.leftWidget = widget;
            this.leftDrawer = true;
        },
        closeLeftDrawer: function () {
            this.leftDrawer = false;
        },
        setRightDrawerWidget: function (widget) {
            this.rightWidget = widget;
            this.rightDrawer = true;
        },
        closeRightDrawer: function () {
            this.rightDrawer = false;
        },
        setRightChatDrawerWidget: function (widget) {
            this.rightChatWidget = widget;
            this.rightChatDrawer = true;
        },
        closeRightChatDrawer: function () {
            this.rightChatDrawer = false;
        },
        setFullscreenModalWidget: function (widget) {
            this.fullScreenWidget = widget;
            this.fullScreenModal = true;
        },
        closeFullscreenModal: function () {
            this.fullScreenModal = false;
        },
        replayUpdateExerciseInfo: function(info) {
            this.exercise.currentTime = (info.currentTime !== undefined) ? info.currentTime : this.exercise.currentTime;
            this.exercise.status = (info.status !== undefined) ? info.status : this.exercise.status;
            this.exercise.speed = (info.speed !== undefined) ? info.speed : this.exercise.speed;
            this.currentTime = this.exercise.currentTime;
        },
        scrollToWidget(anchor) {
            // // let doc = window.document.getElementById(anchor).getBoundingClientRect();//.scrollIntoView()
            // let doc = window.document.getElementById(anchor);

            // window.document.body.scrollTo({
            //     top: 0,
            //     left: 0,
            //     behavior: 'smooth'
            // });
            this.$scrollTo(anchor)
        },
        words() {
            navigator.clipboard.writeText(faker.lorem.words());
        },
        paragraphs() {
            navigator.clipboard.writeText(faker.lorem.paragraphs());
        },
        paragraph() {
            navigator.clipboard.writeText(faker.lorem.paragraph());
        },
    },
    beforeRouteLeave(to, from, next) {
        this.unsubscribe();
        next();
    },
};
</script>

<style scoped>
.floating-clock {
    position: fixed;
    top: 0px;
    left: 10px;
    /* background-color: white;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc; */
    font-size: 4rem;
    z-index: 1000;
}

.floating-role {
    position: fixed;
    top: 10px;
    right: 10px;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    /* border: 1px solid #ccc; */
    z-index: 1000;
    font-size: 2rem;
}

.icon-drawer {
    display: block;
    text-align: center;
    font-size: 1.4rem;
    color: #ccc;
    min-height: 1px;
    height: 50%;
    width: 70px;
    z-index: 19;
}

.icon-drawer-left {
    top: 250px;
    left: 0;
    position: fixed;
}

.icon-drawer-right {
    top: 250px;
    right: 0;
    position: fixed;
}

.drawer {
    display: block;
    background-color: white;
    color: #ccc;
    min-height: 1px;
    height: 100%;
    width: 0;
    z-index: 20;
    /* box-shadow: 0 1px 1px rgba(0,0,0,.05); */
    -webkit-transition: all 2s; /* For Safari 3.1 to 6.0 */
    transition: all 2s;
    overflow-x: hidden;
    overflow-y: auto;
}

.drawer.active {
    width: 40%;
}

.drawer-left {
    border-right: 1px solid #ccc;
    top: 0;
    left: 50px;
    position: fixed;
}

.drawer-right {
    background-color: lightgreen;
    border-left: 1px solid red;
    top: 0;
    right: 0;
    position: fixed;
}

/* .modal-content */
.fullscreen {
    width: 100vw !important;
    height: 100vh !important;
    margin: 0;
    top: 0;
    left: 0;
}

.inline-widget-content-padding {
    padding: 75px;
}
</style>
