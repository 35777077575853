/*!

=========================================================
* BootstrapVue Argon Dashboard PRO - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/bootstrap-vue-argon-dashboard-pro
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Vue from 'vue';
import DashboardPlugin from './plugins/dashboard-plugin';

import store from './store/store';

import axios from 'axios';
import VueAxios from 'vue-axios';
import Vuex from 'vuex'

// import { Socket } from 'socket.io-client';
// import socketio from 'socket.io-client';
// import { io } from "socket.io-client";
import VueSocketIO from 'vue-socket.io';
import VueScrollTo from 'vue-scrollto';

import Multiselect from 'vue-multiselect'
Vue.component('Multiselect', Multiselect)

// import { createPinia, PiniaVuePlugin } from 'pinia';

// import ApiPlugin from './plugins/api';
import App from './App.vue';

// router setup
import router from './routes/router';
// plugin setup
Vue.use(DashboardPlugin);
// Vue.use(ApiPlugin);

const $http = axios.create({
  baseURL: `${process.env.VUE_APP_AXIOS_BASE_URL}`
});

$http.interceptors.request.use(function (config) {
  const token = window.localStorage.getItem('token');
  if(token) {
    config.headers['Authorization'] = token;
  }
  return config;
})

Vue.use(VueAxios, {$http: $http});
Vue.use(Vuex);

// Vue.use(PiniaVuePlugin);
// const pinia = createPinia();

// import SocketIOMixin from '@/util/socketio';
import Widgets from '@/util/widgets';
import Tools from '@/util/tools';
import Visible from '@/util/visible';

// Vue.component('SocketIOMixin', SocketIOMixin);
Vue.mixin(Widgets);
Vue.mixin(Tools);
Vue.mixin(Visible);
// Vue.mixin(SocketIOMixin);

import TimeSince from '@/components/SimEOC/general/TimeSince.vue';
import SimDate from '@/components/SimEOC/general/SimDate.vue';
import SimTime from '@/components/SimEOC/general/SimTime.vue';
import SortInject from '@/components/SimEOC/general/SortInject.vue';

Vue.component('TimeSince', TimeSince);
Vue.component('SimDate', SimDate);
Vue.component('SimTime', SimTime);
Vue.component('SortInject', SortInject);


// SimEOC Utilities.
import ExerciseStatus from '@/components/SimEOC/general/ExerciseStatus.vue';
import ExerciseProgress from '@/components/SimEOC/general/ExerciseProgress.vue';
import CurrentTime from '@/components/SimEOC/utils/CurrentTime';
import ShowSendTime from '@/components/SimEOC/utils/ShowSendTime';
import ShowSendTimeBadge from '@/components/SimEOC/utils/ShowSendTimeBadge';
import FormAction from '@/components/SimEOC/utils/FormAction.vue';

import ShowObject from '@/components/SimEOC/utils/ShowObject';

import FormatDateTime from '@/components/SimEOC/utils/FormatDateTime';
import RoleInfo from '@/components/SimEOC/utils/RoleInfo';
import RoleSimcellInfo from '@/components/SimEOC/utils/RoleSimcellInfo';
import SimcellInfo from '@/components/SimEOC/utils/SimcellInfo';
import UserFullName from '@/components/SimEOC/utils/UserFullName';
import ShowMedia from '@/components/SimEOC/utils/ShowMedia';
import ItemButtonOptions from '@/components/SimEOC/utils/ItemButtonOptions';
import ExerciseItemButtonOptions from '@/components/SimEOC/utils/ExerciseItemButtonOptions';
import FormButtonOptions from '@/components/SimEOC/utils/FormButtonOptions';

// SimEOC Utilities.
Vue.component('CurrentTime', CurrentTime);
Vue.component('ShowSendTime', ShowSendTime);
Vue.component('ShowSendTimeBadge', ShowSendTimeBadge);
Vue.component('ShowObject', ShowObject);
Vue.component('ExerciseStatus', ExerciseStatus);
Vue.component('ExerciseProgress', ExerciseProgress);
Vue.component('FormAction', FormAction);

Vue.component('FormatDateTime', FormatDateTime);
Vue.component('RoleInfo', RoleInfo);
Vue.component('RoleSimcellInfo', RoleSimcellInfo);
Vue.component('SimcellInfo', SimcellInfo);
Vue.component('UserFullName', UserFullName);
Vue.component('ShowMedia', ShowMedia);
Vue.component('ItemButtonOptions', ItemButtonOptions);
Vue.component('ExerciseItemButtonOptions', ExerciseItemButtonOptions);
Vue.component('FormButtonOptions', FormButtonOptions);


import BaseWidget from '@/components/SimEOC/BaseWidget.vue';
import BaseItemWidget from '@/components/SimEOC/BaseItemWidget.vue';

import BaseSimWidget from '@/components/SimEOC/BaseSimWidget.vue';

Vue.component('BaseWidget', BaseWidget);
Vue.component('BaseItemWidget', BaseItemWidget);

Vue.component('BaseSimWidget', BaseSimWidget);

/* A ****************************************************************/
// import ActivityForm from "@/components/SimEOC/activity/ActivityForm.vue";
// import ActivityLibrary from "@/components/SimEOC/activity/ActivityLibrary.vue";
// import ActivityList from "@/components/SimEOC/activity/ActivityList.vue";
// import ActivityHelp from "@/components/SimEOC/activity/ActivityHelp.vue";
// Vue.component('ActivityForm', ActivityForm);
// Vue.component('ActivityLibrary', ActivityLibrary);
// Vue.component('ActivityList', ActivityList);
// Vue.component('ActivityHelp', ActivityHelp);

import Broadcast from '@/components/SimEOC/broadcast/Broadcast.vue';
import BroadcastListItem from '@/components/SimEOC/broadcast/BroadcastListItem.vue';
import BroadcastForm from "@/components/SimEOC/broadcast/BroadcastForm.vue";
import BroadcastLibrary from "@/components/SimEOC/broadcast/BroadcastLibrary.vue";
import BroadcastList from "@/components/SimEOC/broadcast/BroadcastList.vue";
import BroadcastSimWidget from "@/components/SimEOC/broadcast/BroadcastSimWidget.vue";
// import BroadcastHelp from "@/components/SimEOC/broadcast/BroadcastHelp.vue";
Vue.component('Broadcast', Broadcast);
Vue.component('BroadcastListItem', BroadcastListItem);
Vue.component('BroadcastForm', BroadcastForm);
Vue.component('BroadcastLibrary', BroadcastLibrary);
Vue.component('BroadcastList', BroadcastList);
Vue.component('BroadcastSimWidget', BroadcastSimWidget);
// Vue.component('BroadcastHelp', BroadcastHelp);


import ControlWidget from "@/components/SimEOC/control/ControlWidget.vue";
import ControlForm from "@/components/SimEOC/control/ControlForm.vue";
// import ControlLibrary from "@/components/SimEOC/control/ControlLibrary.vue";
// import ControlList from "@/components/SimEOC/control/ControlList.vue";
// import ControlHelp from "@/components/SimEOC/control/ControlHelp.vue";
Vue.component('ControlWidget', ControlWidget);
Vue.component('ControlForm', ControlForm);
// Vue.component('ControlLibrary', ControlLibrary);
// Vue.component('ControlList', ControlList);
// Vue.component('ControlHelp', ControlHelp);


// import GlobalInjectForm from "@/components/SimEOC/global-inject/GlobalInjectForm.vue";
// import GlobalInjectLibrary from "@/components/SimEOC/global-inject/GlobalInjectLibrary.vue";
// import GlobalInjectList from "@/components/SimEOC/global-inject/GlobalInjectList.vue";
// import GlobalInjectHelp from "@/components/SimEOC/global-inject/GlobalInjectHelp.vue";
// Vue.component('GlobalInjectForm', GlobalInjectForm);
// Vue.component('GlobalInjectLibrary', GlobalInjectLibrary);
// Vue.component('GlobalInjectList', GlobalInjectList);
// Vue.component('GlobalInjectHelp', GlobalInjectHelp);

import Inject from '@/components/SimEOC/inject/Inject.vue';
import InjectListItem from '@/components/SimEOC/inject/InjectListItem.vue';
import InjectForm from "@/components/SimEOC/inject/InjectForm.vue";
import InjectLibrary from "@/components/SimEOC/inject/InjectLibrary.vue";
import InjectList from "@/components/SimEOC/inject/InjectList.vue";
import InjectSimWidget from "@/components/SimEOC/inject/InjectSimWidget.vue";
import InjectHelp from "@/components/SimEOC/inject/InjectHelp.vue";
import MySimInjectList from "@/components/SimEOC/inject/MySimInjectList.vue";
import MySimInject from "@/components/SimEOC/inject/MySimInject.vue";
import ExerciseInjectAllInjectList from "@/components/SimEOC/inject/ExerciseInjectAllInjectList.vue";

Vue.component('Inject', Inject);
Vue.component('InjectListItem', InjectListItem);
Vue.component('InjectForm', InjectForm);
Vue.component('InjectLibrary', InjectLibrary);
Vue.component('InjectList', InjectList);
Vue.component('InjectSimWidget', InjectSimWidget);
Vue.component('InjectHelp', InjectHelp);
Vue.component('MySimInjectList', MySimInjectList);
Vue.component('MySimInject', MySimInject);
Vue.component('ExerciseInjectAllInjectList', ExerciseInjectAllInjectList);


import Inventory from '@/components/SimEOC/inventory/Inventory.vue';
import InventoryListItem from '@/components/SimEOC/inventory/InventoryListItem.vue';
import InventoryForm from "@/components/SimEOC/inventory/InventoryForm.vue";
import InventoryLibrary from "@/components/SimEOC/inventory/InventoryLibrary.vue";
import InventoryList from "@/components/SimEOC/inventory/InventoryList.vue";
import InventorySimWidget from "@/components/SimEOC/inventory/InventorySimWidget.vue";
// import InventoryHelp from "@/components/SimEOC/inventory/InventoryHelp.vue";
Vue.component('Inventory', Inventory);
Vue.component('InventoryListItem', InventoryListItem);
Vue.component('InventoryForm', InventoryForm);
Vue.component('InventoryLibrary', InventoryLibrary);
Vue.component('InventoryList', InventoryList);
Vue.component('InventorySimWidget', InventorySimWidget);
// Vue.component('InventoryHelp', InventoryHelp);


import Map from '@/components/SimEOC/map/Map.vue';
import MapPreview from '@/components/SimEOC/map/MapPreview.vue';
import MapCategory from '@/components/SimEOC/map/MapCategory.vue';
import MapListItem from '@/components/SimEOC/map/MapListItem.vue';
import MapForm from "@/components/SimEOC/map/MapForm.vue";
import MapLibrary from "@/components/SimEOC/map/MapLibrary.vue";
import MapList from "@/components/SimEOC/map/MapList.vue";

import MapSimView from "@/components/SimEOC/map/MapSimView.vue";
import MapSimWidget from "@/components/SimEOC/map/MapSimWidget.vue";

// import MapHelp from "@/components/SimEOC/map/MapHelp.vue";
Vue.component('Map', Map);
Vue.component('MapPreview', MapPreview);
Vue.component('MapCategory', MapCategory);
Vue.component('MapListItem', MapListItem);
Vue.component('MapForm', MapForm);
Vue.component('MapLibrary', MapLibrary);
Vue.component('MapList', MapList);
Vue.component('MapSimView', MapSimView);
Vue.component('MapSimWidget', MapSimWidget);
// Vue.component('MapHelp', MapHelp);


import Service from '@/components/SimEOC/service/Service.vue';
import ServiceListItem from '@/components/SimEOC/service/ServiceListItem.vue';
import ServiceForm from "@/components/SimEOC/service/ServiceForm.vue";
import ServiceLibrary from "@/components/SimEOC/service/ServiceLibrary.vue";
import ServiceList from "@/components/SimEOC/service/ServiceList.vue";
// import ServiceHelp from "@/components/SimEOC/service/ServiceHelp.vue";
Vue.component('Service', Service);
Vue.component('ServiceListItem', ServiceListItem);
Vue.component('ServiceForm', ServiceForm);
Vue.component('ServiceLibrary', ServiceLibrary);
Vue.component('ServiceList', ServiceList);
// Vue.component('ServiceHelp', ServiceHelp);



// import NewsForm from "@/components/SimEOC/news/NewsForm.vue";
// import NewsLibrary from "@/components/SimEOC/news/NewsLibrary.vue";
// import NewsList from "@/components/SimEOC/news/NewsList.vue";
// import NewsHelp from "@/components/SimEOC/news/NewsHelp.vue";
// Vue.component('NewsForm', NewsForm);
// Vue.component('NewsLibrary', NewsLibrary);
// Vue.component('NewsList', NewsList);
// Vue.component('NewsHelp', NewsHelp);



// import PersonalInjectForm from "@/components/SimEOC/personal-inject/PersonalInjectForm.vue";
// import PersonalInjectLibrary from "@/components/SimEOC/personal-inject/PersonalInjectLibrary.vue";
// import PersonalInjectList from "@/components/SimEOC/personal-inject/PersonalInjectList.vue";
// import PersonalInjectHelp from "@/components/SimEOC/personal-inject/PersonalInjectHelp.vue";
// Vue.component('PersonalInjectForm', PersonalInjectForm);
// Vue.component('PersonalInjectLibrary', PersonalInjectLibrary);
// Vue.component('PersonalInjectList', PersonalInjectList);
// Vue.component('PersonalInjectHelp', PersonalInjectHelp);


import PositionLogForm from "@/components/SimEOC/position-log/PositionLogForm.vue";
import PositionLog from "@/components/SimEOC/position-log/PositionLog.vue";
// import PositionLogLibrary from "@/components/SimEOC/position-log/PositionLogLibrary.vue";
import PositionLogList from "@/components/SimEOC/position-log/PositionLogList.vue";
import PositionLogSimWidget from "@/components/SimEOC/position-log/PositionLogSimWidget.vue";
// import PositionLogHelp from "@/components/SimEOC/position-log/PositionLogHelp.vue";
Vue.component('PositionLogForm', PositionLogForm);
Vue.component('PositionLog', PositionLog);
// Vue.component('PositionLogLibrary', PositionLogLibrary);
Vue.component('PositionLogList', PositionLogList);
Vue.component('PositionLogSimWidget', PositionLogSimWidget);
// Vue.component('PositionLogHelp', PositionLogHelp);


import ResourceRequest from '@/components/SimEOC/resource-request/ResourceRequest.vue';
import ResourceRequestListItem from '@/components/SimEOC/resource-request/ResourceRequestListItem.vue';
import ResourceRequestForm from "@/components/SimEOC/resource-request/ResourceRequestForm.vue";
import ResourceRequestLibrary from "@/components/SimEOC/resource-request/ResourceRequestLibrary.vue";
import ResourceRequestList from "@/components/SimEOC/resource-request/ResourceRequestList.vue";
import ResourceRequestedList from "@/components/SimEOC/resource-request/ResourceRequestedList.vue";
import ResourceRequestSimWidget from "@/components/SimEOC/resource-request/ResourceRequestSimWidget.vue";
// import ResourceRequestHelp from "@/components/SimEOC/resource-request/ResourceRequestHelp.vue";
Vue.component('ResourceRequest', ResourceRequest);
Vue.component('ResourceRequestListItem', ResourceRequestListItem);
Vue.component('ResourceRequestForm', ResourceRequestForm);
Vue.component('ResourceRequestLibrary', ResourceRequestLibrary);
Vue.component('ResourceRequestList', ResourceRequestList);
Vue.component('ResourceRequestedList', ResourceRequestedList);
Vue.component('ResourceRequestSimWidget', ResourceRequestSimWidget);
// Vue.component('ResourceRequestHelp', ResourceRequestHelp);


import Chat from "@/components/SimEOC/chat/Chat.vue";
import ChatForm from "@/components/SimEOC/chat/ChatForm.vue";
import ChatHelp from "@/components/SimEOC/chat/ChatHelp.vue";
import ChatList from "@/components/SimEOC/chat/ChatList.vue";
import ChatSimWidget from "@/components/SimEOC/chat/ChatSimWidget.vue";
import ChatEntry from "@/components/SimEOC/chat/ChatEntry.vue";
import ChatEntryForm from "@/components/SimEOC/chat/ChatEntryForm.vue";
import ChatEntryList from "@/components/SimEOC/chat/ChatEntryList.vue";
Vue.component('Chat', Chat);
Vue.component('ChatForm', ChatForm);
Vue.component('ChatHelp', ChatHelp);
Vue.component('ChatList', ChatList);
Vue.component('ChatSimWidget', ChatSimWidget);
Vue.component('ChatEntry', ChatEntry);
Vue.component('ChatEntryForm', ChatEntryForm);
Vue.component('ChatEntryList', ChatEntryList);


// import ResourceRequestedForm from "@/components/SimEOC/resource-requested/ResourceRequestedForm.vue";
// import ResourceRequestedLibrary from "@/components/SimEOC/resource-requested/ResourceRequestedLibrary.vue";
// import ResourceRequestedList from "@/components/SimEOC/resource-requested/ResourceRequestedList.vue";
// import ResourceRequestedHelp from "@/components/SimEOC/resource-requested/ResourceRequestedHelp.vue";
// Vue.component('ResourceRequestedForm', ResourceRequestedForm);
// Vue.component('ResourceRequestedLibrary', ResourceRequestedLibrary);
// Vue.component('ResourceRequestedList', ResourceRequestedList);
// Vue.component('ResourceRequestedHelp', ResourceRequestedHelp);



import Role from "@/components/SimEOC/role/Role.vue";
import RoleListItem from "@/components/SimEOC/role/RoleListItem.vue";
import RoleForm from "@/components/SimEOC/role/RoleForm.vue";
import RoleLibrary from "@/components/SimEOC/role/RoleLibrary.vue";
import RoleList from "@/components/SimEOC/role/RoleList.vue";
// import RoleHelp from "@/components/SimEOC/role/RoleHelp.vue";
Vue.component('Role', Role);
Vue.component('RoleListItem', RoleListItem);
Vue.component('RoleForm', RoleForm);
Vue.component('RoleLibrary', RoleLibrary);
Vue.component('RoleList', RoleList);
// Vue.component('RoleHelp', RoleHelp);



import ScribeForm from "@/components/SimEOC/scribe/ScribeForm.vue";
// import ScribeLibrary from "@/components/SimEOC/scribe/ScribeLibrary.vue";
import ScribeList from "@/components/SimEOC/scribe/ScribeList.vue";
import Scribe from "@/components/SimEOC/scribe/Scribe.vue";
import ScribeSimWidget from "@/components/SimEOC/scribe/ScribeSimWidget.vue";
// import ScribeHelp from "@/components/SimEOC/scribe/ScribeHelp.vue";
Vue.component('ScribeForm', ScribeForm);
// Vue.component('ScribeLibrary', ScribeLibrary);
Vue.component('ScribeList', ScribeList);
Vue.component('Scribe', Scribe);
Vue.component('ScribeSimWidget', ScribeSimWidget);
// Vue.component('ScribeHelp', ScribeHelp);

import SignificantEvent from '@/components/SimEOC/significant-event/SignificantEvent.vue';
import SignificantEventListItem from '@/components/SimEOC/significant-event/SignificantEventListItem.vue';
import SignificantEventForm from "@/components/SimEOC/significant-event/SignificantEventForm.vue";
import SignificantEventLibrary from "@/components/SimEOC/significant-event/SignificantEventLibrary.vue";
import SignificantEventList from "@/components/SimEOC/significant-event/SignificantEventList.vue";
import SignificantEventSimWidget from "@/components/SimEOC/significant-event/SignificantEventSimWidget.vue";
// import SignificantEventHelp from "@/components/SimEOC/significant-event/SignificantEventHelp.vue";
Vue.component('SignificantEvent', SignificantEvent);
Vue.component('SignificantEventListItem', SignificantEventListItem);
Vue.component('SignificantEventForm', SignificantEventForm);
Vue.component('SignificantEventLibrary', SignificantEventLibrary);
Vue.component('SignificantEventList', SignificantEventList);
Vue.component('SignificantEventSimWidget', SignificantEventSimWidget);
// Vue.component('SignificantEventHelp', SignificantEventHelp);


import Simcell from '@/components/SimEOC/simcell/Simcell.vue';
import SimcellListItem from '@/components/SimEOC/simcell/SimcellListItem.vue';
import SimcellForm from "@/components/SimEOC/simcell/SimcellForm.vue";
import SimcellLibrary from "@/components/SimEOC/simcell/SimcellLibrary.vue";
import SimcellList from "@/components/SimEOC/simcell/SimcellList.vue";
import SimcellSimWidget from "@/components/SimEOC/simcell/SimcellSimWidget.vue";
import SimcellHelp from "@/components/SimEOC/simcell/SimcellHelp.vue";
Vue.component('Simcell', Simcell);
Vue.component('SimcellListItem', SimcellListItem);
Vue.component('SimcellForm', SimcellForm);
Vue.component('SimcellLibrary', SimcellLibrary);
Vue.component('SimcellList', SimcellList);
Vue.component('SimcellSimWidget', SimcellSimWidget);
Vue.component('SimcellHelp', SimcellHelp);

import SocialMedia from '@/components/SimEOC/social-media/SocialMedia.vue';
import SocialMediaListItem from '@/components/SimEOC/social-media/SocialMediaListItem.vue';
import SocialMediaForm from "@/components/SimEOC/social-media/SocialMediaForm.vue";
import SocialMediaLibrary from "@/components/SimEOC/social-media/SocialMediaLibrary.vue";
import SocialMediaList from "@/components/SimEOC/social-media/SocialMediaList.vue";
// import SocialMediaHelp from "@/components/SimEOC/social-media/SocialMediaHelp.vue";
Vue.component('SocialMedia', SocialMedia);
Vue.component('SocialMediaListItem', SocialMediaListItem);
Vue.component('SocialMediaForm', SocialMediaForm);
Vue.component('SocialMediaLibrary', SocialMediaLibrary);
Vue.component('SocialMediaList', SocialMediaList);
// Vue.component('SocialMediaHelp', SocialMediaHelp);

import Spreadsheet from '@/components/SimEOC/spreadsheet/Spreadsheet.vue';
import SpreadsheetListItem from '@/components/SimEOC/spreadsheet/SpreadsheetListItem.vue';
import SpreadsheetForm from "@/components/SimEOC/spreadsheet/SpreadsheetForm.vue";
import SpreadsheetLibrary from "@/components/SimEOC/spreadsheet/SpreadsheetLibrary.vue";
import SpreadsheetList from "@/components/SimEOC/spreadsheet/SpreadsheetList.vue";
import SpreadsheetSimWidget from "@/components/SimEOC/spreadsheet/SpreadsheetSimWidget.vue";
import SpreadsheetHelp from "@/components/SimEOC/spreadsheet/SpreadsheetHelp.vue";
Vue.component('Spreadsheet', Spreadsheet);
Vue.component('SpreadsheetListItem', SpreadsheetListItem);
Vue.component('SpreadsheetForm', SpreadsheetForm);
Vue.component('SpreadsheetLibrary', SpreadsheetLibrary);
Vue.component('SpreadsheetList', SpreadsheetList);
Vue.component('SpreadsheetSimWidget', SpreadsheetSimWidget);
Vue.component('SpreadsheetHelp', SpreadsheetHelp);

import StatusBoard from '@/components/SimEOC/status-board/StatusBoard.vue';
import StatusBoardListItem from '@/components/SimEOC/status-board/StatusBoardListItem.vue';
import StatusBoardWidget from "@/components/SimEOC/status-board/StatusBoardWidget.vue";
import StatusBoardForm from "@/components/SimEOC/status-board/StatusBoardForm.vue";
import StatusBoardLibrary from "@/components/SimEOC/status-board/StatusBoardLibrary.vue";
import StatusBoardList from "@/components/SimEOC/status-board/StatusBoardList.vue";
import StatusBoardSimWidget from "@/components/SimEOC/status-board/StatusBoardSimWidget.vue";

import StatusBoardStatusList from "@/components/SimEOC/status-board/StatusBoardStatusList.vue";
import StatusBoardStatus from "@/components/SimEOC/status-board/StatusBoardStatus.vue";
import StatusBoardCountStatus from "@/components/SimEOC/status-board/status/StatusBoardCountStatus.vue";
import StatusBoardCountForm from "@/components/SimEOC/status-board/form/StatusBoardCountForm.vue";
import StatusBoardInfo from "@/components/SimEOC/status-board/status/StatusBoardInfo.vue";
import StatusBoardUsedCapacity from "@/components/SimEOC/status-board/status/StatusBoardUsedCapacity.vue";

// import StatusBoardHelp from "@/components/SimEOC/status-board/StatusBoardHelp.vue";
Vue.component('StatusBoard', StatusBoard);
Vue.component('StatusBoardListItem', StatusBoardListItem);
Vue.component('StatusBoardWidget', StatusBoardWidget);
Vue.component('StatusBoardForm', StatusBoardForm);
Vue.component('StatusBoardLibrary', StatusBoardLibrary);
Vue.component('StatusBoardList', StatusBoardList);
Vue.component('StatusBoardSimWidget', StatusBoardSimWidget);

Vue.component('StatusBoardStatusList', StatusBoardStatusList);
Vue.component('StatusBoardStatus', StatusBoardStatus);
Vue.component('StatusBoardCountStatus', StatusBoardCountStatus);
Vue.component('StatusBoardCountForm', StatusBoardCountForm);
Vue.component('StatusBoardInfo', StatusBoardInfo);
Vue.component('StatusBoardUsedCapacity', StatusBoardUsedCapacity);
// Vue.component('StatusBoardHelp', StatusBoardHelp);

import TaskForm from "@/components/SimEOC/task/TaskForm.vue";
// import TaskLibrary from "@/components/SimEOC/task/TaskLibrary.vue";
import TaskList from "@/components/SimEOC/task/TaskList.vue";
import Task from "@/components/SimEOC/task/Task.vue";
import TaskSimWidget from "@/components/SimEOC/task/TaskSimWidget.vue";
// import TaskHelp from "@/components/SimEOC/task/TaskHelp.vue";
Vue.component('TaskForm', TaskForm);
// Vue.component('TaskLibrary', TaskLibrary);
Vue.component('TaskList', TaskList);
Vue.component('Task', Task);
Vue.component('TaskSimWidget', TaskSimWidget);
// Vue.component('TaskHelp', TaskHelp);

import Weather from '@/components/SimEOC/weather/Weather.vue';
import WeatherCurrent from '@/components/SimEOC/weather/WeatherCurrent.vue';
import WeatherListItem from '@/components/SimEOC/weather/WeatherListItem.vue';
import WeatherForm from "@/components/SimEOC/weather/WeatherForm.vue";
import WeatherLibrary from "@/components/SimEOC/weather/WeatherLibrary.vue";
import WeatherList from "@/components/SimEOC/weather/WeatherList.vue";
import WeatherSimWidget from "@/components/SimEOC/weather/WeatherSimWidget.vue";
// import WeatherHelp from "@/components/SimEOC/weather/WeatherHelp.vue";
Vue.component('Weather', Weather);
Vue.component('WeatherCurrent', WeatherCurrent);
Vue.component('WeatherListItem', WeatherListItem);
Vue.component('WeatherForm', WeatherForm);
Vue.component('WeatherLibrary', WeatherLibrary);
Vue.component('WeatherList', WeatherList);
Vue.component('WeatherSimWidget', WeatherSimWidget);
// Vue.component('WeatherHelp', WeatherHelp);

/* A ****************************************************************/

// export const SocketInstance = io(process.env.VUE_APP_AXIOS_BASE_URL);

Vue.use(new VueSocketIO({
  debug: false,
  connection: process.env.VUE_APP_AXIOS_BASE_URL,
  vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
  },
	//options: { path: '/portal' } //Optional options
}))

// Vue.use(VueSocketIO, SocketInstance);

// You can also pass in the default options
Vue.use(VueScrollTo, {
    container: "body",
    duration: 1000,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true
});

import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

import hljs from 'highlight.js'

// highlight.js style
// import 'highlight.js/styles/tomorrow.css'
import 'highlight.js/styles/stackoverflow-light.css';

import 'mapbox-gl/dist/mapbox-gl.css';

import mapboxgl from 'mapbox-gl'; // or "const mapboxgl = require('mapbox-gl');"
mapboxgl.accessToken = 'pk.eyJ1Ijoic2Ftc29ubGlrZSIsImEiOiJjbHE2N3pqbGQwcHRuMnZteGtuZ2wxNWtmIn0.1OnIYyZPjMBA40saFEWI1A';

const editorOption = {
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'font': [] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'align': [] }],
        ['clean'],
        ['link', 'image', 'video']
      ],
      syntax: {
        highlight: text => hljs.highlightAuto(text).value
      }
    }
  };

Vue.use(VueQuillEditor, editorOption);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  // pinia
  store
});
