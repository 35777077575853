<template>
    <div>
        <Task
            class="my-2"
            v-for="item in sortedItemList"
            :key="item._id"
            :input="item"
            :exercise="exercise"
            :inbox="inbox"
            v-on:editinexercise="$emit('editinexercise', $event)"
        />
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import * as _ from "lodash";

import SocketIOMixin from '@/util/socketio';
export default {
    name: "TaskList",
    mixins: [SocketIOMixin],
    components: {},
    props: {
        exercise: {
            type: Object,
            required: false,
            default: Object,
        },
        inbox: {
            type: String,
            required: false,
            default: "in",
            enum: ["in", "out"],
        },
    },
    data: function () {
        return {};
    },
    computed: {

    },
    created: function () {},
    mounted: function () {
        const that = this;

        that.find();

        that.socketIOSubscribe({
            name: `${that.exercise._id}-exercise-mission-task-list`,
            lastCallback: function (data) {
                if(data.status == 'sent' && data.toRole == that.$store.state.sim.role._id) {
                    that.$notify({
                        message: "New Task Received! See options below.",
                        duration: 5000,
                        type: "success"
                    });
                } else if(data.status != 'draft') {
                    that.$notify({
                        message: "Task Updated! See options below.",
                        duration: 5000,
                        type: "success"
                    });
                }
            }
        });
    },
    methods: {
        find: async function () {
            const that = this;
            const response = await that.$http.get("/exercise-mission-task", {
                params: { exercise: that.exercise._id },
            });
            that.itemList = response.data;
        },
    },
};
</script>
